import React, { useState } from "react";
import { Prismic__FileLink } from "types/prismic";
import { PrismicImage } from "types/prismicImage";
import { PrismicRichText } from "types/prismicRichtext";
import { IDashedAnimationsItemProps } from "components/dashed-animation/DashedAnimationsItem";
import { Container } from "components/container/Container";
import { Button } from "components/button/Button";
import { InfoListItem } from "components/contentful/info-list/InfoListItem";
import { RichText } from "components/contentful/rich-text/RichText";
import s from "./InfoList.scss";
import { Link } from "components/link/Link";

interface IProps {
  title?: string | null;
  subtitle?: string | null;
  text?: PrismicRichText | null;
  image?: PrismicImage;
  image_title?: string | null;
  image_cta_label: string | null;
  image_cta_link: string | null;
  items?: Array<IItemProps> | null;
  target?: string;
  layout?: string;
  imageAlignment?: string;
  titlePlacement?: "outside" | "inside";
  titleAlignTop?: boolean;
  titleAsH3?: boolean;
  extraIndent?: boolean;
  overlappedLeftIndent?: boolean;
  itemsTitleAsH2?: boolean;
  accordionCenterVertical?: boolean;
  extraPaddingTop?: boolean;
  contentExtraMarginTop?: boolean;
  extraPaddingBottom?: boolean;
  extraPaddingBottomOnVideo?: boolean;
  lessPaddingTop?: boolean;
  lessPaddingBottom?: boolean;
  bgBlue?: boolean;
  borderRadius?: "top" | "bottom" | "bottomLeft" | "bottomRight";
  dashedAnimations?: Array<Array<IDashedAnimationsItemProps>>;
  hasParallax?: boolean;
  smallerImage?: boolean;
  transitionSimple?: boolean;
  largeVideos?: boolean;
  expandedOnMobile?: boolean;
  lazyLoadVideos?: boolean;
  disableImageLazyLoad?: boolean;
  evenColumns?: boolean;
  sectionMarginBottom?: boolean;
}

interface IItemProps {
  title: string;
  subtitle: string;
  richtext: PrismicRichText;
  img?: PrismicImage;
  image_title: string;
  video?: Prismic__FileLink;
  dashed_icon?: boolean;
}

export const InfoListContentful = ({
  infoList,
  subtitle,
  target,
  layout = "default",
  imageAlignment = "right",
  titlePlacement = "outside",
  titleAlignTop = false,
  informationAlignedTop = false,
  titleAsH3 = false,
  extraIndent = false,
  overlappedLeftIndent = false,
  itemsTitleAsH2 = false,
  accordionCenterVertical = false,
  extraPaddingTop = false,
  contentExtraMarginTop = false,
  extraPaddingBottom = false,
  extraPaddingBottomOnVideo = false,
  lessPaddingTop = false,
  lessPaddingBottom = false,
  bgBlue = false,
  borderRadius = "top",
  dashedAnimations,
  hasParallax = false,
  smallerImage = false,
  transitionSimple = false,
  largeVideos = false,
  smallVideos = false,
  expandedOnMobile = false,
  lazyLoadVideos = false,
  disableImageLazyLoad = false,
  evenColumns = false,
  sectionMarginBottom = false,
  borderBottomLeftRadius = false,
  style
}: any) => {
  const items = infoList.items;
  const title = infoList.title;
  const text = infoList.description;
  const hasItems = items && items.length > 0 ? true : false;
  const hasVideos = items.filter( e => e.video && e.video?.url) ? true : false;
  const image_title = hasItems ? items[0].image_title : null;
  const image_cta_label = hasItems ? items[0].labelComponent : null;
  const image_cta_link = hasItems ? items[0].image_cta_link : null;
  const img = hasItems ? items[0].image : null;
  const cta = infoList.cta;
  const [currentItem, setCurrentItem] = useState(-1);
  const [activeItem, setActiveItem] = useState(0);
  const [activeItemHasImage, setActiveItemHasImage] = useState(true);
  const layoutOverlapped = layout === "overlapped" ? true : false;
  const imageAlignLeft = imageAlignment === "left" ? true : false;
  const titlePlacementInside = titlePlacement === "inside" ? true : false;
  const rowTopNoPadding = title && !text ? true : false;
  const informationTop = informationAlignedTop;
  const onClick = e => {
    setActiveItemHasImage(
      e.currentTarget.getAttribute("data-has-image") === "true" ? true : false
    );
    setActiveItem(parseFloat(e.currentTarget.getAttribute("data-index")));
  };

  // Content
  const content = items?.map((item: any, index: number) => {
    let tempIsActive: boolean = index == activeItem ? true : false;
    const isLastItem = index + 1 == items.length ? true : false;
    const showDashedAnimation = dashedAnimations ? true : false;

    return (
      <InfoListItem
        item={item}
        key={index}
        index={index}
        isActive={tempIsActive}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        activeItem={activeItem}
        activeItemHasImage={activeItemHasImage}
        layoutOverlapped={layoutOverlapped}
        imageAlignLeft={imageAlignLeft}
        itemsTitleAsH2={itemsTitleAsH2}
        borderRadius={borderRadius}
        isLastItem={isLastItem}
        extraIndent={extraIndent}
        onClick={onClick}
        dashedAnimations={
          showDashedAnimation && dashedAnimations && dashedAnimations[index]
            ? dashedAnimations[index]
            : null
        }
        hasParallax={hasParallax}
        smallerImage={smallerImage}
        transitionSimple={transitionSimple}
        largeVideos={largeVideos}
        smallVideos={smallVideos}
        expandedOnMobile={expandedOnMobile}
        lazyLoadVideos={lazyLoadVideos}
        disableImageLazyLoad={disableImageLazyLoad}
        evenColumns={evenColumns || false}
        informationAlignedTop={informationTop}
        borderBottomLeftRadius={borderBottomLeftRadius}
      ></InfoListItem>
    );
  });

  // Title & Description
  const titleElement = (
    <h2 className={s("infoList__title", { titleAsH3, titleAlignTop })}>
      {title}
    </h2>
  );
  const subtitleElement = (
    <h4 className={s("infoList__subtitle")}>{subtitle}</h4>
  );
  const descriptionElement = text ? (
    <div className={s.infoList__description}>
      <RichText richText={text} />
    </div>
  ) : null;
  const imgsPaddingTop = img
    ? (img.file.details.image.height / img.file.details.image.width) * 100
    : 100;
  const indexAria = Math.floor(Math.random() * 1000);

  // Return
  return (
    <div
      style={style}
      className={s("infoList", {
        layoutOverlapped,
        imageAlignLeft,
        titlePlacementInside,
        extraIndent,
        overlappedLeftIndent,
        accordionCenterVertical,
        rowTopNoPadding,
        extraPaddingTop,
        contentExtraMarginTop,
        extraPaddingBottom,
        hasVideos,
        lessPaddingTop,
        lessPaddingBottom,
        bgBlue,
        smallerImage,
        evenColumns,
        informationAlignedTop,
        sectionMarginBottom
      })}
      data-target={target}
    >
      <Container>
        {(title || text) && titlePlacement == "outside" && (
          <div className={s.infoList__row_top}>
            <div className={s.infoList__col_top}>
              {title && !titlePlacementInside && titleElement}
              {subtitle && !titlePlacementInside && subtitleElement}
              {text && !titlePlacementInside && descriptionElement}
              {cta && (
                <Link to={cta.href} className={s("cta")}>
                  {cta.label}
                </Link>
              )}
            </div>
          </div>
        )}
        <div className={s.infoList__row_bottom}>
          <div className={s.infoList__col_bottom}>
            {title && titlePlacementInside && titleElement}
            {subtitle && titlePlacementInside && subtitleElement}
            {text && titlePlacementInside && descriptionElement}
            {cta && (
              <Link to={cta.href} className={s("cta")}>
                {cta.label}
              </Link>
            )}
            <h2
              className={`u-visually-hidden`}
              id={`infolist-heading-${indexAria}`}
            >
              Infolist Items
            </h2>
            <ul aria-labelledby={`infolist-heading-${indexAria}`}>{content}</ul>
          </div>
          <div className={s("infoList__col_bottom", "right", { image_title })}>
            <div style={{ paddingTop: `${imgsPaddingTop}%` }}></div>

            {image_title && (
              <div className={s.over_image}>
                <h3>{image_title}</h3>
                {image_cta_label && (
                  <Button
                    label={image_cta_label}
                    href={image_cta_link}
                    invertedColor={true}
                  ></Button>
                )}
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
